<template>
  <div class="p-user-setting">
    <!-- <div class="header-toolbar"><span>个人设置</span></div>
    <form class="el-form p-user-setting-main" ref="form">
      <div class="el-form-item">
        <label
          class="el-form-item__label"
          style="width: 68px; line-height: 90px"
          >头像</label
        >
        <div class="el-form-item__content" style="margin-left: 68px">
          <div class="avatar">
            <img
              src="//img2.huashi6.com/images/face/a854519926402/2024/07/13/101946_18600915359.jpg?imageMogr2/quality/100/interlace/1/thumbnail/!100x100r/gravity/North/crop/100x100"
            />
            <div class="tip">更换头像</div>
          </div>
        </div>
      </div>

      <div class="el-form-item">
        <label class="el-form-item__label" style="width: 68px">昵称</label>
        <div class="el-form-item__content" style="margin-left: 68px">
          <div class="el-input">
            <input
              type="text"
              autocomplete="off"
              maxlength="10"
              minlength="2"
              class="el-input__inner"
            />
          </div>
          
        </div>
      </div>
      <div class="el-form-item">
        <label class="el-form-item__label" style="width: 68px">生日</label>
        <div class="el-form-item__content" style="margin-left: 68px">
          <div class="el-select birth-year">
            
            <div class="el-input el-input--suffix">
            <input
                type="text"
                readonly="readonly"
                autocomplete="off"
                placeholder="年"
                class="el-input__inner"
                @focus="choice_birth_year"
                @blur="unchoice_birth_year"
                v-model="choice_year"
              /><span class="el-input__suffix"
                ><span class="el-input__suffix-inner"
                  ><i
                    class="el-select__caret el-input__icon el-icon-arrow-up"
                  ></i
                  ></span
                ></span
              >
            </div>
            <div
              class="el-select-dropdown el-popper"
              style="min-width: 90px"
              v-if="is_choice_year"
            >
              <div class="el-scrollbar" style="">
                <div
                  class="el-select-dropdown__wrap el-scrollbar__wrap"
                  style="margin-bottom: -11px; margin-right: -11px"
                >
                  <ul class="el-scrollbar__view el-select-dropdown__list">
                    
                    <li class="el-select-dropdown__item selected" labe="2019">
                      <span>2019</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2018">
                      <span>2018</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2017">
                      <span>2017</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2016">
                      <span>2016</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2015">
                      <span>2015</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2014">
                      <span>2014</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2013">
                      <span>2013</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2012">
                      <span>2012</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2011">
                      <span>2011</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2010">
                      <span>2010</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2009">
                      <span>2009</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2008">
                      <span>2008</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2007">
                      <span>2007</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2006">
                      <span>2006</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2005">
                      <span>2005</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2004">
                      <span>2004</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2003">
                      <span>2003</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2002">
                      <span>2002</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2001">
                      <span>2001</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2000">
                      <span>2000</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1999">
                      <span>1999</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1998">
                      <span>1998</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1997">
                      <span>1997</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1996">
                      <span>1996</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1995">
                      <span>1995</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1994">
                      <span>1994</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1993">
                      <span>1993</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1992">
                      <span>1992</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1991">
                      <span>1991</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1990">
                      <span>1990</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1989">
                      <span>1989</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1988">
                      <span>1988</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1987">
                      <span>1987</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1986">
                      <span>1986</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1985">
                      <span>1985</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1984">
                      <span>1984</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1983">
                      <span>1983</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1982">
                      <span>1982</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1981">
                      <span>1981</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1980">
                      <span>1980</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1979">
                      <span>1979</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1978">
                      <span>1978</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1977">
                      <span>1977</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1976">
                      <span>1976</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1975">
                      <span>1975</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1974">
                      <span>1974</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1973">
                      <span>1973</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1972">
                      <span>1972</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1971">
                      <span>1971</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1970">
                      <span>1970</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1969">
                      <span>1969</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1968">
                      <span>1968</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1967">
                      <span>1967</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1966">
                      <span>1966</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1965">
                      <span>1965</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1964">
                      <span>1964</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1963">
                      <span>1963</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1962">
                      <span>1962</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1961">
                      <span>1961</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1960">
                      <span>1960</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1959">
                      <span>1959</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1958">
                      <span>1958</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1957">
                      <span>1957</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1956">
                      <span>1956</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1955">
                      <span>1955</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1954">
                      <span>1954</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1953">
                      <span>1953</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1952">
                      <span>1952</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1951">
                      <span>1951</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1950">
                      <span>1950</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1949">
                      <span>1949</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1948">
                      <span>1948</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1947">
                      <span>1947</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1946">
                      <span>1946</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1945">
                      <span>1945</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1944">
                      <span>1944</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1943">
                      <span>1943</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1942">
                      <span>1942</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1941">
                      <span>1941</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1940">
                      <span>1940</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1939">
                      <span>1939</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1938">
                      <span>1938</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1937">
                      <span>1937</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1936">
                      <span>1936</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1935">
                      <span>1935</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1934">
                      <span>1934</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1933">
                      <span>1933</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1932">
                      <span>1932</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1931">
                      <span>1931</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1930">
                      <span>1930</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1929">
                      <span>1929</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1928">
                      <span>1928</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1927">
                      <span>1927</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1926">
                      <span>1926</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1925">
                      <span>1925</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1924">
                      <span>1924</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1923">
                      <span>1923</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1922">
                      <span>1922</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1921">
                      <span>1921</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="1920">
                      <span>1920</span>
                    </li>
                  </ul>
                </div>
                <div class="el-scrollbar__bar is-horizontal">
                  <div
                    class="el-scrollbar__thumb"
                    style="transform: translateX(0%)"
                  ></div>
                </div>
                <div class="el-scrollbar__bar is-vertical">
                  <div
                    class="el-scrollbar__thumb"
                    style="transform: translateY(0%)"
                  ></div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="el-select birth-month">
            
            <div class="el-input el-input--suffix">
            <input
                type="text"
                readonly="readonly"
                autocomplete="off"
                placeholder="月"
                class="el-input__inner"
                @focus="choice_birth_month"
                @blur="unchoice_birth_month"
                v-model="choice_month"
              /><span class="el-input__suffix"
                ><span class="el-input__suffix-inner"
                  ><i
                    class="el-select__caret el-input__icon el-icon-arrow-up"
                  ></i
                  ></span
                ></span
              >
            </div>
            <div
              class="el-select-dropdown el-popper"
              v-if="is_choice_month"
              style="min-width: 90px"
            >
              <div class="el-scrollbar" style="">
                <div
                  class="el-select-dropdown__wrap el-scrollbar__wrap"
                  style="margin-bottom: -11px; margin-right: -11px"
                >
                  <ul class="el-scrollbar__view el-select-dropdown__list">
                    
                    <li class="el-select-dropdown__item selected" labe="1">
                      <span>1</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="2">
                      <span>2</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="3">
                      <span>3</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="4">
                      <span>4</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="5">
                      <span>5</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="6">
                      <span>6</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="7">
                      <span>7</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="8">
                      <span>8</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="9">
                      <span>9</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="10">
                      <span>10</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="11">
                      <span>11</span>
                    </li>
                    <li class="el-select-dropdown__item" labe="12">
                      <span>12</span>
                    </li>
                  </ul>
                </div>
                <div class="el-scrollbar__bar is-horizontal">
                  <div
                    class="el-scrollbar__thumb"
                    style="transform: translateX(0%)"
                  ></div>
                </div>
                <div class="el-scrollbar__bar is-vertical">
                  <div
                    class="el-scrollbar__thumb"
                    style="transform: translateY(0%)"
                  ></div>
                </div>
              </div>
              
            </div>
          </div>
          
        </div>
      </div>
      <div class="el-form-item">
        <label class="el-form-item__label" style="width: 68px">性别</label>
        <div class="el-form-item__content" style="margin-left: 68px">
          <label
            role="radio"
            aria-checked="true"
            tabindex="0"
            class="el-radio is-checked"
            ><span class="el-radio__input is-checked"
              ><span class="el-radio__inner"></span
              ><input
                type="radio"
                aria-hidden="true"
                tabindex="-1"
                class="el-radio__original"
                value="1" /></span
            ><span class="el-radio__label">男</span></label
          >
          <label role="radio" tabindex="0" class="el-radio"
            ><span class="el-radio__input"
              ><span class="el-radio__inner"></span
              ><input
                type="radio"
                aria-hidden="true"
                tabindex="-1"
                class="el-radio__original"
                value="2" /></span
            ><span class="el-radio__label">女</span></label
          >
        </div>
      </div>
      <div class="el-form-item user-remark">
        <label class="el-form-item__label" style="width: 68px">简介</label>
        <div class="el-form-item__content" style="margin-left: 68px">
          <div class="el-textarea">
            <textarea
              autocomplete="off"
              class="el-textarea__inner"
              style="min-height: 33px"
            ></textarea
            >
          </div>
          
        </div>
      </div>
      <button
        type="button"
        class="el-button save-btn el-button--primary el-button--mini"
      >
        <span>保存</span>
      </button>
    </form>
    <input
      type="file"
      accept="image/jpeg,image/gif,image/png"
      style="display: none"
    /> -->

    <!-- ------------------  自己写的部分 -------------------- -->
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="头像" prop="head">
        <div class="avatar">
          <a href="javascript:void(0);" @click="change_avator">
            <img :src="cur_avator_img" />
            <div class="tip">更换头像</div>
          </a>
        </div>

        <!-- <div class="el-upload-img">
          <el-upload :http-request="onImport" action="name">
            <el-button slot="trigger" size="mini" type="primary"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </div> -->
        <!-- <el-input v-model="form.head"></el-input> -->
      </el-form-item>
      <el-form-item label="昵称">
        <el-input v-model="form.nick_name"></el-input>
      </el-form-item>
      <el-form-item label="生日">
        <el-select v-model="form.birth_year" placeholder="请选择年份">
          <el-option label="2024" value="2024"></el-option>
          <el-option label="2023" value="2023"></el-option>
          <el-option label="2022" value="2022"></el-option>
          <el-option label="2021" value="2021"></el-option>
          <el-option label="2020" value="2020"></el-option>
          <el-option label="2019" value="2019"></el-option>
          <el-option label="2018" value="2018"></el-option>
          <el-option label="2017" value="2017"></el-option>
          <el-option label="2016" value="2016"></el-option>
          <el-option label="2015" value="2015"></el-option>
          <el-option label="2014" value="2014"></el-option>
          <el-option label="2013" value="2013"></el-option>
          <el-option label="2012" value="2012"></el-option>
          <el-option label="2011" value="2011"></el-option>
          <el-option label="2010" value="2010"></el-option>
          <el-option label="2009" value="2009"></el-option>
          <el-option label="2008" value="2008"></el-option>
          <el-option label="2007" value="2007"></el-option>
          <el-option label="2006" value="2006"></el-option>
          <el-option label="2005" value="2005"></el-option>
          <el-option label="2004" value="2004"></el-option>
          <el-option label="2003" value="2003"></el-option>
          <el-option label="2002" value="2002"></el-option>
          <el-option label="2001" value="2001"></el-option>
          <el-option label="2000" value="2000"></el-option>
          <el-option label="1999" value="1999"></el-option>
          <el-option label="1998" value="1998"></el-option>
          <el-option label="1997" value="1997"></el-option>
          <el-option label="1996" value="1996"></el-option>
          <el-option label="1995" value="1995"></el-option>
          <el-option label="1994" value="1994"></el-option>
          <el-option label="1993" value="1993"></el-option>
          <el-option label="1992" value="1992"></el-option>
          <el-option label="1991" value="1991"></el-option>
          <el-option label="1990" value="1990"></el-option>
          <el-option label="1989" value="1989"></el-option>
          <el-option label="1988" value="1988"></el-option>
          <el-option label="1987" value="1987"></el-option>
          <el-option label="1986" value="1986"></el-option>
          <el-option label="1985" value="1985"></el-option>
          <el-option label="1984" value="1984"></el-option>
          <el-option label="1983" value="1983"></el-option>
          <el-option label="1982" value="1982"></el-option>
          <el-option label="1981" value="1981"></el-option>
          <el-option label="1980" value="1980"></el-option>
          <el-option label="1979" value="1979"></el-option>
          <el-option label="1978" value="1978"></el-option>
          <el-option label="1977" value="1977"></el-option>
          <el-option label="1976" value="1976"></el-option>
          <el-option label="1975" value="1975"></el-option>
          <el-option label="1974" value="1974"></el-option>
          <el-option label="1973" value="1973"></el-option>
          <el-option label="1972" value="1972"></el-option>
          <el-option label="1971" value="1971"></el-option>
          <el-option label="1970" value="1970"></el-option>
          <el-option label="1969" value="1969"></el-option>
          <el-option label="1968" value="1968"></el-option>
          <el-option label="1967" value="1967"></el-option>
          <el-option label="1966" value="1966"></el-option>
          <el-option label="1965" value="1965"></el-option>
          <el-option label="1964" value="1964"></el-option>
          <el-option label="1963" value="1963"></el-option>
          <el-option label="1962" value="1962"></el-option>
          <el-option label="1961" value="1961"></el-option>
          <el-option label="1960" value="1960"></el-option>
          <el-option label="1959" value="1959"></el-option>
          <el-option label="1958" value="1958"></el-option>
          <el-option label="1957" value="1957"></el-option>
          <el-option label="1956" value="1956"></el-option>
          <el-option label="1955" value="1955"></el-option>
          <el-option label="1954" value="1954"></el-option>
          <el-option label="1953" value="1953"></el-option>
          <el-option label="1952" value="1952"></el-option>
          <el-option label="1951" value="1951"></el-option>
          <el-option label="1950" value="1950"></el-option>
          <el-option label="1949" value="1949"></el-option>
          <el-option label="1948" value="1948"></el-option>
          <el-option label="1947" value="1947"></el-option>
          <el-option label="1946" value="1946"></el-option>
          <el-option label="1945" value="1945"></el-option>
          <el-option label="1944" value="1944"></el-option>
          <el-option label="1943" value="1943"></el-option>
          <el-option label="1942" value="1942"></el-option>
          <el-option label="1941" value="1941"></el-option>
          <el-option label="1940" value="1940"></el-option>
          <el-option label="1939" value="1939"></el-option>
          <el-option label="1938" value="1938"></el-option>
          <el-option label="1937" value="1937"></el-option>
          <el-option label="1936" value="1936"></el-option>
          <el-option label="1935" value="1935"></el-option>
          <el-option label="1934" value="1934"></el-option>
          <el-option label="1933" value="1933"></el-option>
          <el-option label="1932" value="1932"></el-option>
          <el-option label="1931" value="1931"></el-option>
          <el-option label="1930" value="1930"></el-option>
          <el-option label="1929" value="1929"></el-option>
          <el-option label="1928" value="1928"></el-option>
          <el-option label="1927" value="1927"></el-option>
          <el-option label="1926" value="1926"></el-option>
          <el-option label="1925" value="1925"></el-option>
          <el-option label="1924" value="1924"></el-option>
          <el-option label="1923" value="1923"></el-option>
          <el-option label="1922" value="1922"></el-option>
          <el-option label="1921" value="1921"></el-option>
          <el-option label="1920" value="1920"></el-option>
          <el-option label="1919" value="1919"></el-option>
          <el-option label="1918" value="1918"></el-option>
          <el-option label="1917" value="1917"></el-option>
          <el-option label="1916" value="1916"></el-option>
          <el-option label="1915" value="1915"></el-option>
          <el-option label="1914" value="1914"></el-option>
          <el-option label="1913" value="1913"></el-option>
          <el-option label="1912" value="1912"></el-option>
          <el-option label="1911" value="1911"></el-option>
          <el-option label="1910" value="1910"></el-option>
          <el-option label="1909" value="1909"></el-option>
          <el-option label="1908" value="1908"></el-option>
          <el-option label="1907" value="1907"></el-option>
          <el-option label="1906" value="1906"></el-option>
          <el-option label="1905" value="1905"></el-option>
          <el-option label="1904" value="1904"></el-option>
          <el-option label="1903" value="1903"></el-option>
          <el-option label="1902" value="1902"></el-option>
          <el-option label="1901" value="1901"></el-option>
          <el-option label="1900" value="1900"></el-option>
        </el-select>
        <el-select v-model="form.birth_month" placeholder="请选择月份">
          <el-option label="1" value="1"></el-option>
          <el-option label="2" value="2"></el-option>
          <el-option label="3" value="3"></el-option>
          <el-option label="4" value="4"></el-option>
          <el-option label="5" value="5"></el-option>
          <el-option label="6" value="6"></el-option>
          <el-option label="7" value="7"></el-option>
          <el-option label="8" value="8"></el-option>
          <el-option label="9" value="9"></el-option>
          <el-option label="10" value="10"></el-option>
          <el-option label="11" value="11"></el-option>
          <el-option label="12" value="12"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="form.gender">
          <el-radio label="男性" value="male"></el-radio>
          <el-radio label="女性" value="female"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" v-model="form.introduction"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>

    <AvatorCaiJian
      v-if="is_show"
      :showVariable="is_show"
      @close_dialog="close_change_avator_dialog"
      @send_avator_url="get_changed_avator"
    ></AvatorCaiJian>
  </div>
</template>

<script>
import { get_token } from "../common/common.js";
import constant from "../config/constant.js";
import AvatorCaiJian from "./AvatorCaiJian.vue";
// import UserSetting from "./UserSetting.vue";
export default {
  name: "UserSetting",
  data() {
    return {
      is_choice_year: false,
      is_choice_month: false,
      choice_year: "",
      choice_month: "",
      form: {
        introduction: "",
        gender: "",
        birth_month: "",
        birth_year: "",
        nick_name: "",
      },
      is_show: false,
      blob_data: [],
      cur_avator_img:
        "//img2.huashi6.com/images/face/a854519926402/2024/07/13/101946_18600915359.jpg?imageMogr2/quality/100/interlace/1/thumbnail/!100x100r/gravity/North/crop/100x100",
    };
  },
  methods: {
    async get_changed_avator(data) {
      // let datas = await data.blob_data;
      // console.log("data: ", data);
      if (data.base64_addr.length > 0) {
        this.cur_avator_img = data.base64_addr;
        // console.log("父组件收到图片文件: ", this.cur_avator_img);
      }

      if (data.blob_data != undefined || data.blob_data == null) {
        this.blob_data = await data.blob_data;
        // console.log("父组件收到图片二进制文件: ", this.blob_data);
      }
    },
    change_avator() {
      this.is_show = true;
    },

    close_change_avator_dialog() {
      this.is_show = false;
    },

    async onSubmit() {
      // console.log("submit!", this.form);
      // console.log(
      //   "参数:",
      //   this.form.nick_name,
      //   this.form.birth_year,
      //   this.form.birth_month,
      //   this.form.gender,
      //   this.form.introduction
      // );
      const token = get_token("token");
      try {
        if (
          !(
            this.form.nick_name &&
            this.form.birth_year &&
            this.form.birth_month &&
            this.form.gender &&
            this.form.introduction
          )
        ) {
          this.$message("内容填写不完整，请重新填写");
          return;
        }

        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/set_user_info", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: {
            nick_name: this.form.nick_name,
            year: this.form.birth_year,
            month: this.form.birth_month,
            gender: this.form.gender,
            introduction: this.form.introduction,
            // img_data: this.cur_avator_img,
          },
          responseType: "json",
        });

        if (response.data.status == 200) {
          // 有更新图片
          if (this.blob_data.size > 0) {

            this.send_avator_img(token);
          } else {
            this.$message({
              message: "个人信息设置成功！",
              type: "success",
            });
          }
        }
        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/set_user_info",
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Access-Control-Allow-Origin": "*", // 设置跨域参数
        //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        //       Authorization: `Bearer ${token}`,
        //     },
        //     data: {
        //       nick_name: this.form.nick_name,
        //       year: this.form.birth_year,
        //       month: this.form.birth_month,
        //       gender: this.form.gender,
        //       introduction: this.form.introduction,
        //       // img_data: this.cur_avator_img,
        //     },
        //   }
        // );
        // return response.data;
      } catch (e) {
        // console.log(e);
      }

      // this.$axios
      //   .post("/api/upload_avator", formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((response) => {
      //     console.log("Image uploaded successfully", response);
      //   })
      //   .catch((error) => {
      //     console.error("Error uploading image", error);
      //   });
    },

    async send_avator_img(token) {
      // console.log("发送图片 send_avator_img", token);
      const formData = new FormData();
      formData.append("image", this.blob_data, "filename.jpg"); // 可以自定义文件名

      // console.log("formdata: ", formData);
      var response = await this.$http({
        method: "post",
        url: "http://www.soutudashi.com/api/upload_avator", // 拼接URL和参数
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*", // 设置跨域参数
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
        responseType: "json",
      });

      // console.log("发送头像： ", response.data);
      if (response.data.status != 200) {
        this.$message({
          message: response.data.message,
          type: "error",
        });
      } else if (response.data.status == 200) {
        this.$message({
          message: response.data.message,
          type: "success",
        });
      }
      // console.log("发送头像图片结果： ", response);
    },

    async get_account_info() {
      const token = get_token("token");
      var response = await this.$http({
        method: "post",
        url: "http://www.soutudashi.com/account_info", // 拼接URL和参数
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // 设置跨域参数
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });

      if (response.data.status == 200) {
        const datas = response.data.data;
        this.form.nick_name = datas.nick_name;
        this.form.birth_year = datas.birth_year;
        this.form.birth_month = datas.birth_month;
        this.form.introduction = datas.introduction;
        this.form.gender = datas.gender;
        this.cur_avator_img =
          constant.GET_IMAGE_ADDRESS + encodeURIComponent(datas.avatar_url);
      }
      // console.log("用户信息： ", response, this.form, this.cur_avator_img);
    },
    choice_birth_year(event) {
      // console.log("选择年份");
      console.log(event.target);
      this.is_choice_year = true;
    },
    unchoice_birth_year(event) {
      // console.log("选好年份了");
      this.is_choice_year = false;
    },
    choice_birth_month(event) {
      // console.log("选择年份");
      console.log(event.target);
      this.is_choice_month = true;
    },
    unchoice_birth_month(event) {
      // console.log("选好年份了");
      this.is_choice_month = false;
    },
  },

  mounted() {
    this.get_account_info();
  },

  components: { AvatorCaiJian },
};
</script>

<style scoped>
.header-toolbar {
  height: 30px;
  background-color: white;
  line-height: 30px;
  text-align: left;
  padding: 10px 20px;
}
.p-user-setting-main {
  margin-top: 10px;
  background-color: white;
}

.el-form-item__content > .avatar {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
}

.el-form-item__content > .avatar > a > img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
